<template>
  <div class="page page--no-header">
    <div class="container">
        <div class="row mb--sm--9 mb--md--20 mt--sm--9 mt--md--20">
            <div class="col col--sm--12 text--sm--center d--sm--flex justify-content--sm--center align-items--sm--center flex-direction--sm--column">
                <FogotPasswordIcon class="mb--sm--2"/>
                <div class="logo-branch mb--sm--9 mb--md--20">Quên mật khẩu</div>
                <HdButton >Liên hệ HOTLINE: 1900 1910</HdButton>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
// Import Components
import HdButton from '@/components/HdButton'

// Import icons
import FogotPasswordIcon from '@/assets/fogot-password.svg'
export default {
  components: {
    HdButton,
    FogotPasswordIcon
  },
  data () {
    return {}
  }
}
</script>
